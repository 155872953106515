import React from "react";
import { Slider } from "antd";

function LightWwOverallWidget() {
  return (
    <div className="widget large light">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">컬러/WW 조명</h1>
        <p className="dashboard-widget-title-place">장소</p>
        <div className="widget-info">
          켜짐
          <img
            src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/light_on.png"
            alt="light"
          />
        </div>
      </div>
      <div className="widget-large-footer light-ww">
        <div className="light-control-container">
          <small>밝기</small>
          <div className="light-slider brightness">
            <Slider defaultValue={50} />
          </div>
        </div>

        <div className="light-control-container">
          <small>색온도</small>
          <div className="light-slider temperature">
            <Slider defaultValue={20} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LightWwOverallWidget;
