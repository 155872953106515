import React from "react";
import { Button } from "antd";

function SmartHubAirConditionerTempWidget() {
  return (
    <div className="widget wide">
      <div className="widget-left">
        <div className="dashboard-widget-title">
          <h1 className="dashboard-widget-title-name">에어컨</h1>
          <p className="dashboard-widget-title-place">장소</p>
        </div>
        <div className="dashboard-widget-footer">
          <span>전원</span>
          <img
            src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/power_gray.png"
            alt="button"
          />
        </div>
      </div>
      <div className="widget-right">
        <div className="temp-title">
          <small>희망온도</small>
          <h2>{`23`}℃</h2>
        </div>
        <div className="widget-buttons">
          <Button>
            <svg
              width={17}
              height={16}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.083 8.667V7.333H3.417v1.334h10.666Z"
                fill="#504D49"
              />
            </svg>
          </Button>
          <Button>
            <svg
              width={17}
              height={16}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.583 7.333V2.667h1.334v4.666h4.666v1.334H8.917v4.666H7.583V8.667H2.917V7.333h4.666Z"
                fill="#504D49"
              />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SmartHubAirConditionerTempWidget;
