import React from "react";
import { Button } from "antd";


function SmartHubAirConditionerOverallWidget() {
  return (
    <div className="widget large">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">에어컨</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="widget-large-info">
        <div className="temp-title">
          <small>희망온도</small>
          <h2>{`23`}℃</h2>
        </div>
        <div className="mode">
          <small>모드</small>
          <h2>냉방</h2>
        </div>
      </div>
      <div className="widget-large-footer air-conditioner">
        <Button>
          <img
            src={`https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/wind_auto.png`}
            alt="wind"
          />
          자동
        </Button>
        <Button>
          <img
            src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/Plus.png"
            alt="plus"
          />
        </Button>
        <Button>
          <img
            src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/Minus.png"
            alt="minus"
          />
        </Button>
        <Button>
          <img
            src={`https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/mode_cold.png`}
            alt="wind"
          />
          냉방
        </Button>
        <Button>
          <img
            src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/power_gray.png"
            alt="button"
          />
          전원
        </Button>
      </div>
    </div>
  );
}

export default SmartHubAirConditionerOverallWidget;
