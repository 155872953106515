import React from 'react';
import SmartDoorLockStatusWidget from "./SmartDoorLockStatusWidget";
import SmartDoorLockBatteryWidget from "./SmartDoorLockBatteryWidget";
import SmartDoorLockPasswordWidget from "./SmartDoorLockPasswordWidget";
import SmartDoorLockOpenDoorWidget from "./SmartDoorLockOpenDoorWidget";
import SmartDoorLockOnlineWidget from "./SmartDoorLockOnlineWidget";
import SmartDoorLockTempPasswordWidget from "./SmartDoorLockTempPasswordWidget";

type Props = {
  selectedWidget: string;
}

function SmartDoorLockWidgetContainer({ selectedWidget }: Props) {

  return (
    <>
      {selectedWidget === 'SmartDoorLockStatusWidget' && <SmartDoorLockStatusWidget />}
      {selectedWidget === 'SmartDoorLockBatteryWidget' && <SmartDoorLockBatteryWidget />}
      {selectedWidget === 'SmartDoorLockOpenDoorWidget' && <SmartDoorLockOpenDoorWidget />}
      {selectedWidget === 'SmartDoorLockPasswordWidget' && <SmartDoorLockPasswordWidget />}
      {selectedWidget === 'SmartDoorLockOnlineWidget' && <SmartDoorLockOnlineWidget />}
      {selectedWidget === 'SmartDoorLockTempPasswordWidget' && <SmartDoorLockTempPasswordWidget />}
    </>
  );
}

export default SmartDoorLockWidgetContainer;

