import { Routes, Route } from 'react-router';
import WidgetListPage from './pages/WidgetListPage';

function WidgetRoute() {
  return (
    <Routes>
      <Route path="/" element={<WidgetListPage />} />
    </Routes>
  );
}

export default WidgetRoute;
