import React from 'react';
import SmokeSensorWidget from "./GasSensorWidget";
import GasSensorBatteryWidget from "./GasSensorBatteryWidget";

type Props = {
  selectedWidget: string;
}

function SensorGasWidgetContainer({ selectedWidget }: Props) {
  return (
    <>
      {selectedWidget === 'GasSensorStatusWidget' && <SmokeSensorWidget />}
      {selectedWidget === 'GasBatteryStatusWidget' && <GasSensorBatteryWidget />}
    </>
  );
}

export default SensorGasWidgetContainer;
