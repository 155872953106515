import { Routes, Route } from 'react-router';
import PidDetailEventListPage from './pages/PidDetailEventListPage';
import PidListPage from './pages/PidListPage';
import PairingManagePage from './pages/PairingManagePage';
function PidRoute() {
  return (
    <Routes>
      <Route path="/" element={<PidListPage />} />
      <Route path="/detail/:id/event/list" element={<PidDetailEventListPage />} />
      <Route path="/detail/:id/pairing/:pairingType" element={<PairingManagePage />} />
    </Routes>
  );
}

export default PidRoute;
