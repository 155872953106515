import React from 'react';
import LightStatusWidget from "./LightStatusWidget";
import LightBrightnessWidget from "./LightBrightnessWidget";
import LightColorOverallWidget from "./LightColorOverallWidget";
import LightColorSaturationWidget from "./LightColorSaturationWidget";
import LightColorWidget from "./LightColorWidget";
import LightWwOverallWidget from "./LightWwOverallWidget";
import LightWwTemperatureWidget from "./LightWwTemperatureWidget";

type Props = {
  selectedWidget: string;
}

function LightWidgetContainer({ selectedWidget }: Props) {

  return (
    <>
      {selectedWidget === 'LightStatusWidget' && <LightStatusWidget />}
      {selectedWidget === 'LightBrightnessWidget' && <LightBrightnessWidget /> }
      {selectedWidget === 'LightColorOverallWidget' && <LightColorOverallWidget /> }
      {selectedWidget === 'LightColorSaturationWidget' && <LightColorSaturationWidget /> }
      {selectedWidget === 'LightColorWidget' && <LightColorWidget /> }
      {selectedWidget === 'LightWwOverallWidget' && <LightWwOverallWidget /> }
      {selectedWidget === 'LightWwTemperatureWidget' && <LightWwTemperatureWidget /> }
    </>
  );
}

export default LightWidgetContainer;

