import React from "react";
import "../Widget.scss";


function SmartDoorLockStatusWidget() {
  return (
    <div className={`widget green`}>
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">무타공 도어락</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>

      <div className="dashboard-widget-info">
        잠김
      </div>
    </div>
  );
}

export default SmartDoorLockStatusWidget;
