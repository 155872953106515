import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import DoorSensorWidgetContainer from '../samples/sensor-door/DoorSensorWidgetContainer';
import MotionSensorWidgetContainer from '../samples/sensor-motion/MotionSensorWidgetContainer';
import RadarSensorContainer from '../samples/sensor-radar/RadarSensorContainer';
import THSensorWidgetContainer from '../samples/sensor-th/THSensorWidgetContainer';
import SensorSmokeWidgetContainer from '../samples/sensor-smoke/SensorSmokeWidgetContainer';
import SensorGasWidgetContainer from '../samples/sensor-gas/SensorGasWidgetContainer';
import SensorWaterWidgetContainer from '../samples/sensor-water/SensorWaterWidgetContainer';
import LightWidgetContainer from '../samples/light/LightWidgetContainer';
import SmartDoorLockWidgetContainer from '../samples/doorlock/SmartDoorLockWidgetContainer';
import SmartSirenWidgetContainer from '../samples/siren/SmartSirenWidgetContainer';
import SwitchWidgetContainer from '../samples/switch/SwitchWidgetContainer';
import HubWidget from '../samples/hub/HubWidget';
import SmartButtonWidget from '../samples/smart-button/SmartButtonWidget';
import SmartHubAirConditionerWidgetContainer from '../samples/airconditioner/SmartHubAirConditionerWidgetContainer';
import SetTopBoxWidgetContainer from '../samples/settopbox/SetTopBoxWidgetContainer';

import { Space, Table, Button, Modal, Select } from 'antd';
import '../samples/Widget.scss';

const { Column } = Table;
const { Option } = Select;

type WidgetDetail = {
  deviceType: string;
  essentials: string[];
  widgets: [
    {
      default: boolean;
      widgetName: string;
      data: string[];
      style: {
        h: number;
        w: number;
      };
    }
  ];
};

function WidgetList() {
  const [widgets, setWidgets] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [widgetDetail, setWidgetDetail] = useState<WidgetDetail | null>(null);
  const [showWidgetModal, setShowWidgetModal] = useState(false);
  const [showWidgetUpdateModal, setShowWidgetUpdateModal] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState('');

  async function getWidgets() {
    const { data } = await axios.get('https://develop-goqual-admin-api.goqual.io/v1.0/admin/widgets');
    return data;
  }

  const onClickShowModal = (type: string, widgetName: string, widget_value: string) => {
    setModalTitle(widgetName);
    setWidgetDetail(JSON.parse(widget_value));

    if (type === 'detail') {
      setShowWidgetModal(true);
    } else if (type === 'update') {
      setShowWidgetUpdateModal(true);
    }
  };

  const hideModal = (type: string) => {
    if (type === 'detail') {
      setShowWidgetModal(false);
    } else {
      setShowWidgetUpdateModal(false);
    }
  };

  useEffect(() => {
    getWidgets().then(data => {
      setWidgets(data.result);
    });
  }, []);

  const defaultWidget = useMemo(() => {
    return widgetDetail?.widgets.filter(widget => widget.default === true)[0];
  }, [widgetDetail]);

  useEffect(() => {
    if (defaultWidget) {
      setSelectedWidget(defaultWidget.widgetName);
    }
  }, [defaultWidget, showWidgetModal]);

  const resolvedWidget = useMemo(() => {
    switch (widgetDetail?.deviceType) {
      case 'SENSOR_DO':
        return <DoorSensorWidgetContainer selectedWidget={selectedWidget} />;
      case 'SENSOR_MO':
        return <MotionSensorWidgetContainer selectedWidget={selectedWidget} />;
      case 'SENSOR_RADAR':
        return <RadarSensorContainer selectedWidget={selectedWidget} />;
      case 'SENSOR_TH':
      case 'SENSOR_TH_METER':
      case 'SENSOR_REF_TH':
        return <THSensorWidgetContainer selectedWidget={selectedWidget} />;
      case 'SENSOR_SMOKE':
        return <SensorSmokeWidgetContainer selectedWidget={selectedWidget} />;
      case 'SENSOR_GAS':
        return <SensorGasWidgetContainer selectedWidget={selectedWidget} />;
      case 'SENSOR_WATER':
        return <SensorWaterWidgetContainer selectedWidget={selectedWidget} />;
      case 'LIGHT_WW':
      case 'LIGHT_RGBW':
      case 'LED_STRIP_RGBW':
      case 'MOOD_LIGHT':
        return <LightWidgetContainer selectedWidget={selectedWidget} />;
      case 'ZIGBEE_DOORLOCK':
        return <SmartDoorLockWidgetContainer selectedWidget={selectedWidget} />;
      case 'AUDIBLE_ALARM':
        return <SmartSirenWidgetContainer selectedWidget={selectedWidget} />;
      case 'SWITCH_1':
      case 'ZIGBEE_SWITCH_1':
      case 'SWITCH_2':
      case 'ZIGBEE_SWITCH_2':
      case 'SWITCH_3':
      case 'ZIGBEE_SWITCH_3':
      case 'SWITCH_4':
      case 'ZIGBEE_SWITCH_4':
      case 'SWITCH_5':
      case 'ZIGBEE_SWITCH_5':
      case 'SWITCH_6':
      case 'ZIGBEE_SWITCH_6':
        return <SwitchWidgetContainer selectedWidget={selectedWidget} />;
      case 'SMART_HUB':
      case 'SMART_HUB_AIR_NEW':
        return <HubWidget />;
      case 'SMART_BUTTON':
        return <SmartButtonWidget />;
      case 'SMART_HUB_AIRCONDITION':
        return <SmartHubAirConditionerWidgetContainer selectedWidget={selectedWidget} />;
      case 'SMART_HUB_SET_TOP':
      case 'SMART_HUB_TV':
        return <SetTopBoxWidgetContainer selectedWidget={selectedWidget} />;
      default:
        return;
    }
  }, [selectedWidget, widgetDetail?.deviceType]);

  const onWidgetChange = useCallback((widget: string) => {
    setSelectedWidget(widget);
  }, []);

  return (
    <div className="sku-list">
      <Table sticky={true} dataSource={widgets} rowKey="id">
        <Column title="ID" dataIndex="id" key="id" />
        <Column
          title="상품명"
          dataIndex="product_basic_info"
          key="product_basic_info"
          render={text => text.product_name}
        />
        <Column
          title="이름"
          dataIndex="widget_name"
          key="widget_name"
          render={(text, record: { id: number; widget_value: string }) => (
            <Space size="middle" key={record.id}>
              <Button
                type="dashed"
                onClick={() => {
                  onClickShowModal('detail', text, record.widget_value);
                }}
              >
                위젯 보기
              </Button>
              <Button
                type="dashed"
                onClick={() => {
                  onClickShowModal('update', text, record.widget_value);
                }}
              >
                위젯 수정
              </Button>
            </Space>
          )}
        />
        <Column
          title="생성일"
          dataIndex="created_at"
          key="created_at"
          render={text => moment(text).format('YYYY-MM-DD HH:mm:ss')}
        />
        <Column
          title="수정일"
          dataIndex="updated_at"
          key="updated_at"
          render={text => moment(text).format('YYYY-MM-DD HH:mm:ss')}
        />
      </Table>
      <Modal
        title={modalTitle}
        visible={showWidgetModal}
        bodyStyle={{ background: '#F7F4F1' }}
        onCancel={() => {
          hideModal('detail');
        }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        {widgetDetail && (
          <>
            <p>PRODUCT_TYPE : {widgetDetail.deviceType}</p>
            {widgetDetail.essentials.map((essential, index) => {
              return <p key={index}>필수 : {essential}</p>;
            })}
            <Select value={selectedWidget} onChange={onWidgetChange}>
              {widgetDetail.widgets.map((widget, index) => {
                return (
                  <Option key={index} value={widget.widgetName}>
                    {widget.widgetName}
                  </Option>
                );
              })}
            </Select>
          </>
        )}

        {resolvedWidget}
      </Modal>

      <Modal
        title={modalTitle}
        visible={showWidgetUpdateModal}
        onCancel={() => {
          hideModal('update');
        }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        Widget Update Modal
      </Modal>
    </div>
  );
}

export default WidgetList;
