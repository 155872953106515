import { Routes, Route } from 'react-router';
import CategoryListPage from './pages/CategoryListPage';
function PidRoute() {
  return (
    <Routes>
      <Route path="/category" element={<CategoryListPage />} />
    </Routes>
  );
}

export default PidRoute;
