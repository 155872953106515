import { Routes, Route } from 'react-router';
import UserListPage from './pages/UserListPage';
import UserDeviceListPage from './pages/UserDeviceListPage';
import PidDetailPage from './pages/PidDetailPage';
function UserRoute() {
  return (
    <Routes>
      <Route path="/" element={<UserListPage />} />
      <Route path="/:id/devices" element={<UserDeviceListPage />} />
      <Route path="/:id/devices/:pid" element={<PidDetailPage />} />
    </Routes>
  );
}

export default UserRoute;
