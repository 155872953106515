import React from "react";

function SetTopBoxWidget() {
  return (
    <div className="widget">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">셋톱박스 / TV</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-footer">
        전원
        <img
          src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/power_gray.png"
          alt="button"
        />
      </div>
    </div>
  );
}

export default SetTopBoxWidget;
