import React from 'react';
import SmartHubAirConditionerTempWidget from "./SmartHubAirConditionerTempWidget";
import SmartHubAirConditionerOverallWidget from "./SmartHubAirConditionerOverallWidget";

type Props = {
  selectedWidget: string;
}

function SmartHubAirConditionerContainer({ selectedWidget }: Props) {

  return (
    <>
      {selectedWidget === 'SmartHubAirConditionerTempWidget' && <SmartHubAirConditionerTempWidget />}
      {selectedWidget === 'SmartHubAirConditionerOverallWidget' && <SmartHubAirConditionerOverallWidget />}
    </>
  );
}

export default SmartHubAirConditionerContainer;

