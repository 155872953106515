import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space, Modal, Table, Select } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
const { Column } = Table;
const { Option } = Select;

function PidList() {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [categories, setCategories] = useState([]);
  const [submitForm, setSubmitForm] = useState({
    sync_platform_id: '',
    product_category_id: '',
    pid: '',
    deviceType: '',
    product_name: '',
    pair_easy: '',
    pair_advance: '',
    pair_ble: '',
    pair_zigbee: '',
    isReleased: '',
    isRecipeAction: '',
    isRecipeAlarm: '',
    pairing_data: '{}',
    isDeleted: 0
  });

  const INSERT_CONDITION = [
    { code: 'SYNC_PLATFORM', label: '연동 플랫폼', formId: 'sync_platform_id' },
    { code: 'PRODUCT_CATEGORY', label: '카테고리', formId: 'product_category_id' },
    { code: 'PRODUCT_ID', label: 'PID', formId: 'pid' },
    { code: 'DEVICE_TYPE', label: '디바이스 타입', formId: 'deviceType' },
    { code: 'PRODUCT_NAME', label: '상품명', formId: 'product_name' },
    { code: 'PAIR_MODE_EASY', label: '쉬운연결', formId: 'pair_easy' },
    { code: 'PAIR_MODE_ADVANCE', label: '고급연결', formId: 'pair_advance' },
    { code: 'PAIR_MODE_BLE', label: 'BLE 연결', formId: 'pair_ble' },
    { code: 'PAIR_MODE_ZIGBEE', label: 'ZIGBEE', formId: 'pair_zigbee' },
    { code: 'IS_RELEASE', label: '출시여부', formId: 'isReleased' },
    { code: 'IS_RECIPE_ACTION', label: '레시피 지원', formId: 'isRecipeAction' },
    { code: 'IS_RECIPE_ALARM', label: '레시피 알람 지원', formId: 'isRecipeAlarm' }
  ];

  type platform = {
    id: string;
    package_name: string;
    platform_name: string;
    app_schema: string;
    platform_type_code: string;
  };

  type category = {
    id: string;
    category_code: string;
    category_name: string;
  };

  useEffect(() => {
    axios.get('https://develop-goqual-admin-api.goqual.io/v1.0/admin/products').then(response => {
      const data = response.data;
      setData(data.result);
    });
  }, []);

  const showInsertProductModal = async () => {
    const { data: getPlatforms } = await axios.get('https://develop-goqual-admin-api.goqual.io/v1.0/admin/syncplatforms');
    const { data: getProductCategories } = await axios.get('https://develop-goqual-admin-api.goqual.io/v1.0/admin/products/categories');
    setPlatforms(getPlatforms.result);
    setCategories(getProductCategories.result);
    setIsModalVisible(prevBoolean => !prevBoolean);
  };

  const handleOnchangeFormData = (code: string, value: string) => {
    setSubmitForm(prevState => {
      return { ...prevState, ...{ [code]: value } };
    });
  };

  const handleSubmit = async () => {
    await axios.put('https://develop-goqual-admin-api.goqual.io/v1.0/admin/product', { ...submitForm });
  };

  return (
    <div className="sku-list">
      <div className="buttons-box">
        <Button type="primary" onClick={showInsertProductModal}>
          제품 신규 등록
        </Button>
      </div>
      {data && (
        <Table sticky={true} dataSource={data} rowKey="id">
          <Column title="ID" dataIndex="id" key="id" />
          <Column
            title="연동플랫폼"
            dataIndex="syncPlatformInfo"
            key="syncPlatformInfo"
            render={text => text.platform_name}
          />
          <Column
            title="PID"
            dataIndex="pid"
            key="pid"
            render={text => (
              <Space size="middle" key={text}>
                <Link to={`/pids/detail/${text}/event/list`}>{text}</Link>
              </Space>
            )}
          />
          <Column title="상품명" dataIndex="product_name" key="product_name" />
          <Column
            title="상품 카테고리"
            dataIndex="productCategory"
            key="productCategory"
            render={text => text.category_name}
          />
          <Column
            title="Pair_Easy"
            dataIndex="pair_easy"
            key="pair_easy"
            render={(text, record: { id: number }) =>
              text === 0 ? (
                'No'
              ) : (
                <Space size="middle" key={text}>
                  <Link to={`/pids/detail/${record.id}/pairing/pair_ez`}>Yes</Link>
                </Space>
              )
            }
          />
          <Column
            title="Pair_Advance"
            dataIndex="pair_advance"
            key="pair_advance"
            render={(text, record: { id: number }) =>
              text === 0 ? (
                'No'
              ) : (
                <Space size="middle" key={text}>
                  <Link to={`/pids/detail/${record.id}/pairing/pair_ap`}>Yes</Link>
                </Space>
              )
            }
          />
          <Column
            title="Pair_BLE"
            dataIndex="pair_ble"
            key="pair_ble"
            render={(text, record: { id: number }) =>
              text === 0 ? (
                'No'
              ) : (
                <Space size="middle" key={text}>
                  <Link to={`/pids/detail/${record.id}/pairing/pair_ble`}>Yes</Link>
                </Space>
              )
            }
          />
          <Column
            title="Pair_Zigbee"
            dataIndex="pair_zigbee"
            key="pair_zigbee"
            render={(text, record: { id: number }) =>
              text === 0 ? (
                'No'
              ) : (
                <Space size="middle" key={text}>
                  <Link to={`/pids/detail/${record.id}/pairing/pair_zig`}>Yes</Link>
                </Space>
              )
            }
          />
          <Column
            title="출시여부"
            dataIndex="isReleased"
            key="isReleased"
            render={text => (text === 0 ? 'No' : 'Yes')}
          />
          <Column title="삭제여부" dataIndex="isDeleted" key="isDeleted" render={text => (text === 0 ? 'No' : 'Yes')} />
          <Column
            title="레시피 지원"
            dataIndex="isRecipeAction"
            key="isRecipeAction"
            render={text => (text === 0 ? 'No' : 'Yes')}
          />
          <Column
            title="레시피 알람 지원"
            dataIndex="isRecipeAlarm"
            key="isRecipeAlarm"
            render={text => (text === 0 ? 'No' : 'Yes')}
          />
          <Column
            title="생성일"
            dataIndex="created_at"
            key="created_at"
            render={text => moment(text).format('YYYY-MM-DD HH:mm:ss')}
          />
          <Column
            title="수정일"
            dataIndex="updated_at"
            key="updated_at"
            render={text => moment(text).format('YYYY-MM-DD HH:mm:ss')}
          />
        </Table>
      )}
      <Modal
        title="상품추가"
        visible={isModalVisible}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit}>
          {INSERT_CONDITION.map(condition => {
            switch (condition.code) {
              case 'SYNC_PLATFORM':
                return (
                  <Form.Item
                    key={condition.code}
                    label={condition.label}
                    name={condition.formId}
                    rules={[
                      {
                        required: true,
                        message: `${condition.label} !`
                      }
                    ]}
                  >
                    <Select
                      placeholder="선택하세요"
                      allowClear
                      onChange={(value: string) => {
                        handleOnchangeFormData(condition.formId, value);
                      }}
                    >
                      {platforms.map((platforms: platform) => {
                        return <Option key={platforms.id}>{platforms.package_name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                );

              case 'PRODUCT_CATEGORY':
                return (
                  <Form.Item
                    key={condition.code}
                    label={condition.label}
                    name={condition.formId}
                    rules={[
                      {
                        required: true,
                        message: `${condition.label} !`
                      }
                    ]}
                  >
                    <Select
                      placeholder="선택하세요"
                      allowClear
                      onChange={(value: string) => {
                        handleOnchangeFormData(condition.formId, value);
                      }}
                    >
                      {categories.map((category: category) => {
                        return <Option key={category.id}>{category.category_name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                );
              case 'PRODUCT_ID':
              case 'PRODUCT_NAME':
              case 'DEVICE_TYPE':
                return (
                  <Form.Item
                    key={condition.code}
                    label={condition.label}
                    name={condition.formId}
                    rules={[
                      {
                        required: true,
                        message: `${condition.label} !`
                      }
                    ]}
                  >
                    <Input
                      placeholder="입력하세요."
                      name={condition.code}
                      required={true}
                      onChange={e => {
                        handleOnchangeFormData(condition.formId, e.target.value);
                      }}
                    />
                  </Form.Item>
                );
              case 'PAIR_MODE_EASY':
              case 'PAIR_MODE_ADVANCE':
              case 'PAIR_MODE_BLE':
              case 'PAIR_MODE_ZIGBEE':
              case 'IS_RELEASE':
              case 'IS_RECIPE_ACTION':
              case 'IS_RECIPE_ALARM':
                return (
                  <Form.Item
                    key={condition.code}
                    label={condition.label}
                    name={condition.formId}
                    rules={[
                      {
                        required: true,
                        message: `${condition.label} !`
                      }
                    ]}
                  >
                    <Select
                      placeholder="선택하세요"
                      allowClear
                      onChange={(value: string) => {
                        handleOnchangeFormData(condition.formId, value);
                      }}
                    >
                      <Option value={1}>Yes</Option>
                      <Option value={0}>No</Option>
                    </Select>
                  </Form.Item>
                );
              default:
                return '';
            }
          })}
          <Form.Item wrapperCol={{ offset: 18, span: 16 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                저장
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                취소
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default PidList;
