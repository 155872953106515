import React from 'react';
import { Card, Input, Image } from 'antd';
const { TextArea } = Input;

type Data = {
  data: {
    Multi: [
      { step: string; title: string; content: string; media: [{ mediaSrc: string; mediaType: string; order: number }] }
    ];
    guideLink: string;
  };
  onChange: any;
};

function PairingZigbeeMode(props: Data) {
  return (
    <div className="sku-list">
      {JSON.stringify(props.data) !== '{}' &&
        props.data.Multi.map(item => {
          return (
            <React.Fragment key={item.step}>
              <Card title={`[${item.step}] ${item.title}`} style={{ width: '1000px' }}>
                <TextArea rows={4} value={item.content} maxLength={6} onChange={props.onChange} />
                {item.media.map(item => {
                  return (
                    <React.Fragment key={item.order}>
                      <Input value={item.mediaSrc} onChange={props.onChange} />
                      <Image
                        width={200}
                        src={`https://square-pairing.s3.ap-northeast-2.amazonaws.com${item.mediaSrc}`}
                      />
                    </React.Fragment>
                  );
                })}
              </Card>
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default PairingZigbeeMode;
