import { NavigationBar } from './NavigationBar';
// import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { Layout } from 'antd';
import AppRoute from '../AppRoute';
const { Content } = Layout;
// type Props = {
//   children: JSX.Element | ReactNode;
// } & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const PageLayout = () => {
  return (
    <Layout style={{ height: '100vh' }}>
      <NavigationBar />
      <Layout className="site-layout" style={{ margin: '0 1rem' }}>
        <Content>
          <AppRoute />
        </Content>
      </Layout>
    </Layout>
  );
};
