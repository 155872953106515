import React from 'react';
import WaterSensorWidget from "./WaterSensorWidget";
import WaterSensorBatteryWidget from "./WaterSensorBatteryWidget";

type Props = {
  selectedWidget: string;
}

function SensorWaterWidgetContainer({ selectedWidget }: Props) {
  return (
    <>
      {selectedWidget === 'WaterSensorStatusWidget' && <WaterSensorWidget />}
      {selectedWidget === 'WaterSensorBatteryWidget' && <WaterSensorBatteryWidget />}
    </>
  );
}

export default SensorWaterWidgetContainer;
