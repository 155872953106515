import React from "react";
import { Slider } from "antd";


function LightBrightnessWidget() {
  return (
    <div className={`widget wide light`}>
      <div className="widget-left">
        <div className="dashboard-widget-title">
          <small>조명</small>
          <h1 className="dashboard-widget-title-name">
            {"컬러 밝기"}
          </h1>
          <p className="dashboard-widget-title-place">장소</p>
        </div>
      </div>
      <div className="widget-right">
        켜짐
        <img
          src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/light_on.png"
          alt="light"
        />
      </div>
      <div className="dashboard-widget-footer">
        <div>
          <Slider defaultValue={12} />
        </div>
      </div>
    </div>
  );
}

export default LightBrightnessWidget;
