import React from 'react';
import DoorSensorStatusWidget from "./DoorSensorStatusWidget";
import DoorSensorBatteryWidget from "./DoorSensorBatteryWidget";

type Props = {
  selectedWidget: string;
}

function DoorSensorWidgetContainer({ selectedWidget }: Props) {

  return (
    <>
      {selectedWidget === 'DoorSensorStatusWidget' && <DoorSensorStatusWidget />}
      {selectedWidget === 'DoorSensorBatteryWidget' && <DoorSensorBatteryWidget />}
    </>
  );
}

export default DoorSensorWidgetContainer;
