import { Card, Col, Row, Skeleton, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { Link } from 'react-router-dom';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const { Column } = Table;

function Dashboard() {
  const { data /*error*/ } = useSWR('https://develop-goqual-admin-api.goqual.io/v1.0/influx');
  const top3: {
    productKey: string;
    count: number;
  }[] = useMemo(() => {
    if (data && data.pidCountList && data.pidCountList.length > 0) {
      return data.pidCountList.slice(0, 3);
    }
    return [];
  }, [data]);
  const pidTableList = useMemo(() => {
    if (data && data.pidCountList && data.pidCountList.length > 0) {
      return data.pidCountList.slice(3);
    }
    return [];
  }, [data]);
  const [chartData, setChartData] = useState<{
    count: {
      label: string;
      data: number[];
    }[];
    times: string[];
  }>({ count: [], times: [] });

  const getChartData = () => {
    axios.get('https://develop-goqual-admin-api.goqual.io/v1.0/influx/chart').then(response => {
      const data = response.data;
      setChartData(data.result);
    });
  };
  useEffect(() => {
    getChartData();
  }, []);
  return (
    <div className="App">
      <Row gutter={16}>
        {top3.length > 0 ? (
          top3.map((pid, index) => {
            return (
              <Col span={8} key={pid.productKey}>
                <Link to={`/pids/detail/${pid.productKey}`}>
                  <Card title={pid.productKey} bordered={false}>
                    {pid.count}
                  </Card>
                </Link>
              </Col>
            );
          })
        ) : (
          <Col span={8}>
            <Card bordered={false}>
              <Skeleton />
            </Card>
          </Col>
        )}
      </Row>
      {chartData && (
        <Line
          datasetIdKey="count"
          data={{
            labels: chartData.times,
            datasets: chartData.count
          }}
          redraw={true}
        />
      )}
      {pidTableList && pidTableList.length > 0 && (
        <Table sticky={true} dataSource={pidTableList}>
          <Column title="ProductKey" dataIndex="productKey" key="productKey" />
          <Column title="Count" dataIndex="count" key="count" />
        </Table>
      )}
    </div>
  );
}

export default Dashboard;
