import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Space, Table } from 'antd';
import { Link, useParams } from 'react-router-dom';

const { Column } = Table;
function UserDeviceList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(`https://develop-goqual-admin-api.goqual.io/v1.0/admin/users/${id}/devices`).then(response => {
      const data = response.data;
      setData(data.result);
    });
  }, [id]);
  return (
    <div>
      {data && (
        <Table sticky={true} dataSource={data}>
          <Column title="ID" dataIndex="id" key="id" />
          <Column title="DEVICEID" dataIndex="device_id" key="device_id" />
          <Column title="DEVICENAME" dataIndex="device_name" key="device_name" />
          <Column
            title="PID"
            dataIndex="pid"
            key="pid"
            render={text => (
              <Space size="middle" key={text}>
                <Link to={`/users/${id}/devices/${text}`}>{text}</Link>
              </Space>
            )}
          />
        </Table>
      )}
    </div>
  );
}
export default UserDeviceList;
