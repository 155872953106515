import React from 'react';
import THSensorTempWidget from "./THSensorTempWidget";
import THSensorHumWidget from "./THSensorHumWidget";
import THSensorOverallWidget from "./THSensorOverallWidget";
import THSensorBatteryWidget from "./THSensorBatteryWidget";

type Props = {
  selectedWidget: string;
}

function THSensorWidgetContainer({ selectedWidget }: Props) {
  return (
    <>
      {selectedWidget === 'THSensorTempWidget' && <THSensorTempWidget />}
      {selectedWidget === 'THSensorHumWidget' && <THSensorHumWidget />}
      {selectedWidget === 'THSensorOverallWidget' && <THSensorOverallWidget />}
      {selectedWidget === 'THSensorBatteryWidget' && <THSensorBatteryWidget />}
    </>
  );
}

export default THSensorWidgetContainer;
