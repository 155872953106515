import React from 'react';
import SmartSirenAlarmWidget from "./SmartSirenAlarmWidget";
import SmartSirenBatteryWidget from "./SmarSirenBatteryWidget";
import SmartSirenStatusWidget from "./SmartSirenStatusWidget";

type Props = {
  selectedWidget: string;
}

function SmartSirenWidgetContainer({ selectedWidget }: Props) {
  return (
    <>
      {selectedWidget === 'SmartSirenAlarmWidget' && <SmartSirenAlarmWidget />}
      {selectedWidget === 'SmartSirenPowerWidget' && <SmartSirenStatusWidget />}
      {selectedWidget === 'SmartSirenStatusWidget' && <SmartSirenBatteryWidget />}
    </>
  );
}

export default SmartSirenWidgetContainer;
