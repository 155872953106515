import React from "react";

function SmartSirenAlarmWidget() {
  const powerOn = false;

  return (
    <div className={`widget ${powerOn ? "water-green" : ""}`}>
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">스마트 사이렌</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-footer">
        <span>{powerOn ? "켜짐" : "꺼짐"}</span>
        <svg
          width={20}
          height={20}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.833 10.833v-10H9.167v10h1.666Z"
            fill={powerOn ? "#00A872" : "#797672"}
          />
          <path
            d="M2.5 10c0-2.775 1.507-5.2 3.75-6.497L5.417 2.06a9.167 9.167 0 1 0 9.168 0l-.835 1.443A7.5 7.5 0 1 1 2.5 10Z"
            fill={powerOn ? "#00A872" : "#797672"}
          />
        </svg>
      </div>
    </div>
  );
}

export default SmartSirenAlarmWidget;
