import React from "react";
import { Button } from "antd";

function SetTopBoxControlWidget() {
  return (
    <div className="widget wide">
      <div className="widget-left">
        <div className="dashboard-widget-title">
          <h1 className="dashboard-widget-title-name">셋톱박스 / TV</h1>
          <p className="dashboard-widget-title-place">장소</p>
        </div>
        <div className="dashboard-widget-footer">
          <span>전원</span>
          <img
            src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/power_gray.png"
            alt="button"
          />
        </div>
      </div>

      <div className="widget-right">
        <div className="settopbox-buttons">
          <Button>
            <img src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/arrow_left.png" alt="arrow_left" />
          </Button>
          <Button>
            <img src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/Plus.png" alt="Plus" />
          </Button>
          <Button>
            <img src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/Minus.png" alt="minus" />
          </Button>
          <Button>
            <img src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/arrow_right.png" alt="arrow_right" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SetTopBoxControlWidget;
