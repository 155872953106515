import React from "react";

type Props = {
  battery: number;
};

function Battery({ battery }: Props) {
  if (battery < 21) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)" fill="#BB3A3A">
          <path d="M4.167 6.667a.833.833 0 0 0-.834.833v5A.833.833 0 0 0 5 12.5v-5a.833.833 0 0 0-.833-.833Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.12 3.35c-.145-.017-.304-.017-.489-.017H3.468c-.44 0-.818 0-1.13.026-.33.027-.658.086-.973.247A2.5 2.5 0 0 0 .272 4.698c-.16.316-.22.643-.247.973C0 5.983 0 6.36 0 6.8V13.2c0 .44 0 .818.025 1.13.027.33.087.657.247.973a2.5 2.5 0 0 0 1.093 1.092c.315.16.643.22.972.247.313.026.692.026 1.13.026h12.164c.185 0 .344 0 .488-.017a2.5 2.5 0 0 0 2.198-2.197c.017-.144.016-.304.016-.489V13.848h.002l.066-.033 1.138-.57A.833.833 0 0 0 20 12.5v-5a.833.833 0 0 0-.46-.745l-1.14-.57a5.464 5.464 0 0 1-.065-.033h-.001V6.035c0-.185 0-.345-.017-.489a2.5 2.5 0 0 0-2.198-2.197ZM15.59 5c.25 0 .303.001.339.005a.833.833 0 0 1 .732.733c.004.036.006.09.006.338v.016a1.667 1.667 0 0 0 .803 1.485c.06.036.121.067.171.092l.692.346v3.97l-.692.346c-.05.025-.112.056-.17.092a1.667 1.667 0 0 0-.804 1.485v.016c0 .248-.002.302-.006.338a.833.833 0 0 1-.732.732c-.036.005-.09.006-.339.006H3.5c-.48 0-.79 0-1.027-.02-.226-.018-.31-.05-.351-.07a.833.833 0 0 1-.364-.365c-.022-.041-.053-.125-.071-.351-.02-.237-.02-.547-.02-1.027V6.833c0-.48 0-.79.02-1.026.018-.227.05-.31.07-.352a.833.833 0 0 1 .365-.364c.04-.021.125-.053.351-.071C2.71 5 3.02 5 3.5 5h12.09Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h20v20H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" fill="#00A872">
        <path d="M4.167 6.667a.833.833 0 0 0-.834.833v5c0 .46.373.833.834.833h10c.46 0 .833-.373.833-.833v-5a.833.833 0 0 0-.833-.833h-10Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.12 3.35c-.145-.017-.304-.017-.489-.017H3.468c-.44 0-.818 0-1.13.026-.33.027-.658.086-.973.247A2.5 2.5 0 0 0 .272 4.698c-.16.316-.22.643-.247.973C0 5.983 0 6.36 0 6.8V13.2c0 .44 0 .818.025 1.13.027.33.087.657.247.973a2.5 2.5 0 0 0 1.093 1.092c.315.16.643.22.972.247.313.026.692.026 1.13.026h12.164c.185 0 .344 0 .488-.017a2.5 2.5 0 0 0 2.198-2.197c.017-.144.016-.304.016-.489V13.848h.002a6.63 6.63 0 0 1 .066-.033l1.138-.57A.833.833 0 0 0 20 12.5v-5a.833.833 0 0 0-.46-.745l-1.14-.57a6.408 6.408 0 0 1-.065-.033h-.001V6.035c0-.185 0-.345-.017-.489a2.5 2.5 0 0 0-2.198-2.197ZM15.59 5c.25 0 .303.001.339.005a.833.833 0 0 1 .732.733c.004.036.006.09.006.338v.016a1.667 1.667 0 0 0 .803 1.485c.06.036.121.067.171.092l.692.346v3.97l-.692.346c-.05.025-.112.056-.17.092a1.667 1.667 0 0 0-.804 1.485v.016c0 .248-.002.302-.006.338a.833.833 0 0 1-.732.732c-.036.005-.09.006-.339.006H3.5c-.48 0-.79 0-1.027-.02-.226-.018-.31-.05-.351-.07a.833.833 0 0 1-.364-.365c-.022-.041-.053-.125-.071-.351-.02-.237-.02-.547-.02-1.027V6.833c0-.48 0-.79.02-1.026.018-.227.05-.31.07-.352a.833.833 0 0 1 .365-.364c.04-.021.125-.053.351-.071C2.71 5 3.02 5 3.5 5h12.09Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Battery;
