import React, { useMemo } from 'react';
import { Button, Switch } from 'antd';

type WidgetProps = {
  deviceName: string;
  status: any;
};

function SwitchControlWidget({ deviceName, status }: WidgetProps) {
  const resolvedPower = useMemo(() => {
    const array = [];

    for (const [key, value] of Object.entries(status)) {
      const map = {
        [key]: value
      };
      array.push(map);
    }

    return array;
  }, [status]);

  if (resolvedPower.length === 2) {
    return (
      <div className="widget wide">
        <div className="widget-left">
          <div className="dashboard-widget-title">
            <h1 className="dashboard-widget-title-name">{deviceName}</h1>
            <p className="dashboard-widget-title-place">장소</p>
          </div>
          <div className="dashboard-widget-footer switch">
            <Switch size="small" />
            <span>모두 켜기</span>
          </div>
        </div>

        <div className="widget-right switches">
          {Object.keys(status).map((key, i) => {
            return (
              <Button key={i} className={`switch-button ${status[key as 'power1'] ? 'active' : ''}`}>
                <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.167 5.536V.833h1.666v4.703l7.312 8.936a.834.834 0 0 1-.645 1.361h-3.417a4.168 4.168 0 0 1-8.166 0H2.5a.833.833 0 0 1-.645-1.36l7.312-8.937ZM10 7.149l-5.741 7.018H15.74L10 7.149Zm-2.358 8.684a2.501 2.501 0 0 0 4.716 0H7.642Z"
                    fill="#797672"
                  />
                </svg>
                스위치 {i + 1}구
              </Button>
            );
          })}
        </div>
      </div>
    );
  }

  if (resolvedPower.length === 3) {
    return (
      <div className="widget large">
        <div className="dashboard-widget-title">
          <h1 className="dashboard-widget-title-name">{deviceName}</h1>
          <p className="dashboard-widget-title-place">장소</p>
          <div className="widget-control">
            <Switch size="small" />
            <span>모두 켜기</span>
          </div>
        </div>
        <div className="dashboard-widget-footer switches-3">
          {Object.keys(status).map((key, i) => {
            return (
              <Button key={i} className={`switch-button ${status[key as 'power1'] ? 'active' : ''}`}>
                <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.167 5.536V.833h1.666v4.703l7.312 8.936a.834.834 0 0 1-.645 1.361h-3.417a4.168 4.168 0 0 1-8.166 0H2.5a.833.833 0 0 1-.645-1.36l7.312-8.937ZM10 7.149l-5.741 7.018H15.74L10 7.149Zm-2.358 8.684a2.501 2.501 0 0 0 4.716 0H7.642Z"
                    fill="#797672"
                  />
                </svg>
                스위치 {i + 1}구
              </Button>
            );
          })}
        </div>
      </div>
    );
  }

  if (resolvedPower.length === 4) {
    return (
      <div className="widget large">
        <div className="dashboard-widget-title">
          <h1 className="dashboard-widget-title-name">{deviceName}</h1>
          <p className="dashboard-widget-title-place">장소</p>
          <div className="widget-control">
            <Switch size="small" />
            <span>모두 켜기</span>
          </div>
        </div>
        <div className="dashboard-widget-footer switches-4">
          {Object.keys(status).map((key, i) => {
            return (
              <Button key={i} className={`switch-button ${status[key as 'power1'] ? 'active' : ''}`}>
                <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.167 5.536V.833h1.666v4.703l7.312 8.936a.834.834 0 0 1-.645 1.361h-3.417a4.168 4.168 0 0 1-8.166 0H2.5a.833.833 0 0 1-.645-1.36l7.312-8.937ZM10 7.149l-5.741 7.018H15.74L10 7.149Zm-2.358 8.684a2.501 2.501 0 0 0 4.716 0H7.642Z"
                    fill="#797672"
                  />
                </svg>
                스위치 {i + 1}구
              </Button>
            );
          })}
        </div>
      </div>
    );
  }

  if (resolvedPower.length === 5) {
    return (
      <div className="widget large">
        <div className="dashboard-widget-title">
          <h1 className="dashboard-widget-title-name">{deviceName}</h1>
          <p className="dashboard-widget-title-place">장소</p>
          <div className="widget-control">
            <Switch size="small" />
            <span>모두 켜기</span>
          </div>
        </div>
        <div className="dashboard-widget-footer switches-5">
          {Object.keys(status).map((key, i) => {
            return (
              <Button key={i} className={`switch-button ${status[key as 'power1'] ? 'active' : ''}`}>
                <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.167 5.536V.833h1.666v4.703l7.312 8.936a.834.834 0 0 1-.645 1.361h-3.417a4.168 4.168 0 0 1-8.166 0H2.5a.833.833 0 0 1-.645-1.36l7.312-8.937ZM10 7.149l-5.741 7.018H15.74L10 7.149Zm-2.358 8.684a2.501 2.501 0 0 0 4.716 0H7.642Z"
                    fill="#797672"
                  />
                </svg>
                스위치 {i + 1}구
              </Button>
            );
          })}
        </div>
      </div>
    );
  }

  if (resolvedPower.length === 6) {
    return (
      <div className="widget large">
        <div className="dashboard-widget-title">
          <h1 className="dashboard-widget-title-name">{deviceName}</h1>
          <p className="dashboard-widget-title-place">장소</p>
          <div className="widget-control">
            <Switch size="small" />
            <span>모두 켜기</span>
          </div>
        </div>
        <div className="dashboard-widget-footer switches-6">
          {Object.keys(status).map((key, i) => {
            return (
              <Button key={i} className={`switch-button ${status[key as 'power1'] ? 'active' : ''}`}>
                <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.167 5.536V.833h1.666v4.703l7.312 8.936a.834.834 0 0 1-.645 1.361h-3.417a4.168 4.168 0 0 1-8.166 0H2.5a.833.833 0 0 1-.645-1.36l7.312-8.937ZM10 7.149l-5.741 7.018H15.74L10 7.149Zm-2.358 8.684a2.501 2.501 0 0 0 4.716 0H7.642Z"
                    fill="#797672"
                  />
                </svg>
                스위치 {i + 1}구
              </Button>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`widget ${
        status.power1 || status.power2 || status.power3 || status.power4 || status.power5 || status.power6
          ? 'yellow'
          : ''
      }`}
    >
      <div className="dashboard-widget-title">
        <small>{deviceName}</small>
        <h1 className="dashboard-widget-title-name">{deviceName}</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-footer">
        <span>
          {status.power1 || status.power2 || status.power3 || status.power4 || status.power5 || status.power6
            ? '켜짐'
            : '꺼짐'}
        </span>
        <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.167 5.536V.833h1.666v4.703l7.312 8.936a.833.833 0 0 1-.645 1.361h-3.417a4.168 4.168 0 0 1-8.166 0H2.5a.833.833 0 0 1-.645-1.36l7.312-8.937ZM10 7.149l-5.741 7.018H15.74L10 7.149Zm-2.358 8.684a2.501 2.501 0 0 0 4.716 0H7.642Z"
            fill="#797672"
          />
        </svg>
      </div>
    </div>
  );
}

export default SwitchControlWidget;
