import React from "react";
import { Button } from "antd";
import "../Widget.scss";

function SmartDoorLockPasswordWidget() {
  return (
    <div className="widget wide">
      <div className="widget-left">
        <div className="dashboard-widget-title">
          <h1 className="dashboard-widget-title-name">무타공 도어락</h1>
          <p className="dashboard-widget-title-place">장소</p>
        </div>
        <div className="dashboard-widget-text">임시 비밀번호 만들기</div>
      </div>

      <div className="widget-right">
        <div className="doorlock-widget-buttons">
          <Button>
            기간제
            <svg
              width={12}
              height={13}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#a)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.778.833h.444c.821 0 1.468 0 1.99.043.53.043.974.133 1.377.339a3.5 3.5 0 0 1 1.53 1.53c.205.402.295.846.338 1.377.043.52.043 1.168.043 1.99v.444c0 .82 0 1.468-.043 1.988-.043.532-.133.975-.339 1.378a3.5 3.5 0 0 1-1.529 1.53c-.403.205-.847.295-1.378.339-.52.042-1.168.042-1.989.042h-.444c-.821 0-1.468 0-1.99-.042-.53-.044-.974-.134-1.377-.34a3.5 3.5 0 0 1-1.53-1.529C.676 9.52.586 9.076.543 8.544.5 8.024.5 7.377.5 6.556V6.11c0-.821 0-1.468.043-1.989.043-.531.133-.975.338-1.378a3.5 3.5 0 0 1 1.53-1.53c.403-.205.847-.295 1.378-.338.52-.043 1.168-.043 1.989-.043ZM3.87 1.873c-.469.038-.766.111-1.005.233a2.5 2.5 0 0 0-1.093 1.092c-.121.239-.194.536-.233 1.006-.039.475-.039 1.081-.039 1.93v.4c0 .848 0 1.454.04 1.929.038.47.11.767.232 1.005a2.5 2.5 0 0 0 1.093 1.093c.239.121.536.195 1.005.233.475.039 1.082.04 1.93.04h.4c.848 0 1.455-.001 1.93-.04.469-.038.766-.112 1.005-.233a2.5 2.5 0 0 0 1.092-1.093c.122-.238.195-.536.234-1.005.039-.475.039-1.081.039-1.93v-.4c0-.848 0-1.454-.04-1.93-.038-.469-.11-.766-.233-1.005a2.5 2.5 0 0 0-1.092-1.092c-.239-.122-.536-.195-1.005-.233-.475-.04-1.082-.04-1.93-.04h-.4c-.848 0-1.455 0-1.93.04Zm1.38 5.71v-4.75h1v3.969l2.54-1.173.42.908-3.25 1.5a.5.5 0 0 1-.71-.454Z"
                  fill="#797672"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path
                    fill="#fff"
                    transform="translate(0 .333)"
                    d="M0 0h12v12H0z"
                  />
                </clipPath>
              </defs>
            </svg>
          </Button>
          <Button>
            일회용
            <svg
              width={12}
              height={12}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.224 1.166a1.991 1.991 0 0 0-.8-.166V0c.426 0 .832.089 1.2.25l-.4.916Zm-1.6 0a2.004 2.004 0 0 0-.6.406l-.7-.714c.258-.254.563-.46.9-.608l.4.916ZM7.258 2.2a2.005 2.005 0 0 0-.434-.628l.7-.714c.273.267.495.586.65.942l-.916.4Zm-3.668 0a1.992 1.992 0 0 0-.166.8h-1c0-.426.089-.832.25-1.2l.916.4Zm3.668 1.6c.107-.244.166-.515.166-.8h1c0 .426-.089.832-.25 1.2l-.916-.4ZM5.424 1.5a1.5 1.5 0 0 0-1.5 1.5v4.793l-.243-.243A1.424 1.424 0 0 0 1.58 9.468l1.45 1.74A2.202 2.202 0 0 0 4.72 12h4.472a1.5 1.5 0 0 0 1.48-1.481l.001-.02V8.582c0-.356 0-.651-.019-.894a2.03 2.03 0 0 0-.164-.711 2 2 0 0 0-.748-.854 2.03 2.03 0 0 0-.683-.257c-.238-.05-.531-.09-.884-.137l-.019-.002-1.233-.165V3a1.5 1.5 0 0 0-1.5-1.5Zm1.5 6v-.929l1.101.147c.377.05.631.084.827.126.19.04.288.08.357.124a1 1 0 0 1 .373.427c.034.074.061.176.076.37.016.2.016.456.016.836v1.905A.5.5 0 0 1 9.18 11H4.721c-.357 0-.695-.158-.923-.432l-1.45-1.74a.424.424 0 0 1 .626-.571L4.07 9.354A.5.5 0 0 0 4.924 9V3a.5.5 0 0 1 1 0v4.5h1Z"
                fill="#797672"
              />
            </svg>
          </Button>
          <Button>
            5분 유효
            <svg
              width={12}
              height={13}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m3.46 10.907-3-.242.08-.997 1.559.126a5 5 0 1 1 2.79 1.749l.222-.975A4 4 0 1 0 3 9.313l-.001-1.646h1v2.742a.5.5 0 0 1-.54.498Z"
                fill="#797672"
              />
              <path
                d="M5.25 3.667v3.5a.5.5 0 0 0 .276.447l2 1 .448-.895-1.724-.861V3.667h-1Z"
                fill="#797672"
              />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SmartDoorLockPasswordWidget;
