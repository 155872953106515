import React from "react";

function RadarSensorWidget() {

  return (
    <div className={`widget`}>
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">레이더센서</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div
        className={`dashboard-widget-info time detected`}
      >
        오후 04:12
      </div>
    </div>
  );
}

export default RadarSensorWidget;
