import React from 'react';
import SmokeSensorWidget from "./SmokeSensorWidget";
import SmokeSensorBatteryWidget from "./SmokeSensorBatteryWidget";

type Props = {
  selectedWidget: string;
}

function SensorSmokeWidgetContainer({ selectedWidget }: Props) {
  return (
    <>
      {selectedWidget === 'SmokeSensorStatusWidget' && <SmokeSensorWidget />}
      {selectedWidget === 'SmokeSensorBatteryWidget' && <SmokeSensorBatteryWidget />}
    </>
  );
}

export default SensorSmokeWidgetContainer;
