import React from "react";

function GasSensorWidget() {

  return (
    <div className={`widget red`}>
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">가스 감지 센서</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-info">감지됨</div>
    </div>
  );
}

export default GasSensorWidget;
