import React from "react";

function THSensorOverallWidget() {

  return (
    <div className={`widget wide`}>
      <div className="widget-left">
        <div className="dashboard-widget-title">
          <h1 className="dashboard-widget-title-name te">온습도센서</h1>
          <p className="dashboard-widget-title-place">장소</p>
        </div>
        <div className="dashboard-widget-footer thSensor">
          <img
            src={`https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/th_sensor.png`}
            alt="thSensor"
          />
        </div>
      </div> 
      <div className="widget-right thSensor">
        <div className="current-temp">
          <small>현재 온도</small>
          <p className="dashboard-widget-info temperature">
            {`23\u2103`}
          </p>
        </div>

        <div className="current-humi">
          <small>현재 습도</small>
          <p className="dashboard-widget-info humidity">
            32%
          </p>
        </div>
        {/*<p className="current-title">현재 온도</p>*/}
        {/*<p className="dashboard-widget-info temperature">*/}
        {/*  {`23\u2103`}*/}
        {/*</p>*/}
        {/*<p className="current-title">현재 습도</p>*/}
        {/*<p className="dashboard-widget-info humidity">32%</p>*/}
      </div>
    </div>
  );
}

export default THSensorOverallWidget;
