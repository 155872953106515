import React from "react";

function THSensorHumWidget() {

  return (
    <div className="widget">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">온습도 계/온습도 센서</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-info humidity">77%</div>
    </div>
  );
}

export default THSensorHumWidget;
