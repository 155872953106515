import React from "react";


function SmartSirenAlarmWidget() {
  return (
    <div className={`widget red`}>
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">스마트 사이렌</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-info">
        경보 발생
      </div>
    </div>
  );
}

export default SmartSirenAlarmWidget;
