import React from 'react';
import MotionSensorWidget from "./MotionSensorWidget";
import MotionSensorOverallWidget from "./MotionSensorOverallWidget";
import MotionSensorBatteryWidget from "./MotionSensorBatteryWidget";

type Props = {
  selectedWidget: string;
}

function MotionSensorWidgetContainer({ selectedWidget }: Props) {

  return (
    <>
      {selectedWidget === 'MotionSensorAlarmWidget' && <MotionSensorWidget />}
      {selectedWidget === 'MotionSensorOverallWidget' && <MotionSensorOverallWidget />}
      {selectedWidget === 'MotionSensorBatteryWidget' && <MotionSensorBatteryWidget />}
    </>
  );
}

export default MotionSensorWidgetContainer;

