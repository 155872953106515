import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Space, Table } from 'antd';
import axios from 'axios';
const { Column } = Table;

function CategoryList() {
  const [form] = Form.useForm();
  const [category, setCategory] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categoryCode, setCategoryCode] = useState('');
  const [categoryName, setCategoryName] = useState('');

  async function getCategories() {
    const { data } = await axios.get('https://develop-goqual-admin-api.goqual.io/v1.0/admin/products/categories');
    return data;
  }

  useEffect(() => {
    getCategories().then(data => {
      setCategory(data.result);
    });
  }, []);

  const showInsertProductCategoryModal = async () => {
    setIsModalVisible(prevBoolean => !prevBoolean);
  };

  const handleSubmit = async () => {
    await axios.put('https://develop-goqual-admin-api.goqual.io/v1.0/admin/products/category', {
      category_code: categoryCode,
      category_name: categoryName
    });
    form.resetFields();
    setIsModalVisible(false);
    getCategories().then(data => {
      setCategory(data.result);
    });
  };

  return (
    <div className="sku-list">
      <div className="buttons-box">
        <Button type="primary" onClick={showInsertProductCategoryModal}>
          제품 카테고리 등록
        </Button>
      </div>
      <Table sticky={true} dataSource={category} rowKey="id">
        <Column title="ID" dataIndex="id" key="id" />
        <Column title="CODE" dataIndex="category_code" key="category_code" />
        <Column title="NAME" dataIndex="category_name" key="category_name" />
      </Table>

      <Modal
        title="Product Category"
        visible={isModalVisible}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
      >
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit}>
          <Form.Item
            label="CategoryCode"
            name="CategoryCode"
            rules={[
              {
                required: true,
                message: 'Category Code를 입력하세요!'
              }
            ]}
          >
            <Input
              name="category_code"
              value={categoryCode}
              required={true}
              onChange={e => {
                setCategoryCode(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="CategoryName"
            name="CategoryName"
            rules={[
              {
                required: true,
                message: 'Category Name를 입력하세요!'
              }
            ]}
          >
            <Input
              name="category_name"
              value={categoryName}
              required={true}
              onChange={e => {
                setCategoryName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 18, span: 16 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                저장
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                }}
              >
                취소
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CategoryList;
