import React from "react";
import { Button, Slider } from "antd";

function LightColorOverallWidget() {
  return (
    <div className={`widget tall light yellow`}>
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">컬러 조명</h1>
        <p className="dashboard-widget-title-place">장소</p>
        <div className="widget-info">
          켜짐
          <svg
            width={20}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#a)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.137 0h1.726c.751 0 1.358 0 1.85.034.508.034.957.107 1.382.283a4.167 4.167 0 0 1 2.254 2.255c.176.425.25.873.284 1.38.034.494.034 1.1.034 1.852v2.881a6.511 6.511 0 0 1-2.646 5.24.325.325 0 0 0-.132.262v.585c0 .913-.558 1.724-1.389 2.06v.668a2.5 2.5 0 0 1-5 0v-.667a2.224 2.224 0 0 1-1.389-2.061v-.585c0-.103-.049-.2-.132-.262a6.511 6.511 0 0 1-2.646-5.24V5.804c0-.752 0-1.358.034-1.851.035-.508.108-.956.284-1.38A4.167 4.167 0 0 1 5.906.316c.424-.176.873-.249 1.38-.283C7.78 0 8.386 0 9.137 0Zm.03 17.198v.302a.833.833 0 0 0 1.666 0v-.302a7.882 7.882 0 0 1-1.666 0ZM7.4 1.696c-.423.03-.669.083-.857.161A2.5 2.5 0 0 0 5.19 3.21c-.077.188-.131.434-.16.856-.023.327-.028.723-.03 1.24a12.21 12.21 0 0 0 10 0 19.947 19.947 0 0 0-.03-1.24c-.029-.422-.083-.668-.16-.856a2.5 2.5 0 0 0-1.353-1.353c-.188-.078-.434-.132-.857-.16-.43-.03-.979-.03-1.767-.03H9.167c-.788 0-1.338 0-1.767.03ZM15 7.112a13.878 13.878 0 0 1-10 0v1.573c0 1.539.73 2.986 1.968 3.899.51.375.81.97.81 1.603v.585c0 .25.168.47.41.537l.175.048a6.22 6.22 0 0 0 3.274 0l.175-.048a.557.557 0 0 0 .41-.537v-.585c0-.633.3-1.228.81-1.603A4.844 4.844 0 0 0 15 8.685V7.112Z"
                fill="#797672"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div className="dashboard-widget-footer light">
        <div className="mode buttons">
          <Button>
            <img
              src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/button-icons/mode_switch.png"
              alt="mode"
            />
            모드 전환
          </Button>
          <Button>미리보기</Button>
        </div>
        <div
          className={`color buttons`}
        >
          <small>컬러 모드</small>
          <div className="light-slider color">
            <Slider defaultValue={32} />
          </div>
          <div className="light-slider saturation">
            <Slider defaultValue={1} />
          </div>
          <div className="light-slider brightness">
            <Slider defaultValue={100} />
          </div>
        </div>

        <div
          className={`white buttons`}
        >
          <small>화이트 모드</small>
          <div className="light-slider brightness">
            <Slider defaultValue={100} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LightColorOverallWidget;
