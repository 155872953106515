import React from 'react';
import SetTopBoxWidget from "./SetTopBoxWidget";
import SetTopBoxControlWidget from "./SetTopBoxControlWidget";

type Props = {
  selectedWidget: string;
}

function SetTopBoxWidgetContainer({ selectedWidget }: Props) {

  return (
    <>
      {selectedWidget === 'SetTopBoxWidget' && <SetTopBoxWidget />}
      {selectedWidget === 'SetTopBoxControlWidget' && <SetTopBoxControlWidget />}
    </>
  );
}

export default SetTopBoxWidgetContainer;

