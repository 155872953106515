import React from "react";

function DoorSensorStatusWidget(): React.ReactElement {

  return (
    <div className={`widget blue`}>
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">도어센서</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div
        className={`dashboard-widget-info alarm open`}
      >
        열림
      </div>
    </div>
  );
}

export default DoorSensorStatusWidget;
