import './App.css';
import { Routes, Route } from 'react-router-dom';
import HomePage from './home/pages/HomePage';
import PidRoute from './pids/PidRoute';
import UserRoute from './users/UserRoutes';
import CategoryRoute from './categories/CategoryRoute';
import WidgetRoute from './widgets/WidgetRoute';
function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/pids/*" element={<PidRoute />} />
      <Route path="/users/*" element={<UserRoute />} />
      <Route path="/product/*" element={<CategoryRoute />} />
      <Route path="/widgets/*" element={<WidgetRoute />} />
    </Routes>
  );
}

export default App;
