import React from "react";

function SmartDoorLockOpenDoorWidget() {
  return (
    <div className="widget">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">스마트 도어락</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-footer">
        <p>문 열기</p>
        <svg
          width={20}
          height={20}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#a)" fill="#797672">
            <path d="M9.167 14.167c.23 0 .416-.373.416-.834 0-.46-.186-.833-.416-.833-.23 0-.417.373-.417.833 0 .46.187.834.417.834Z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.63.833c-1.37 0-2.447 0-3.315.071-.885.073-1.625.223-2.297.565A5.833 5.833 0 0 0 1.47 4.02c-.342.671-.492 1.41-.565 2.296-.07.868-.07 1.946-.07 3.315v.74c0 1.37 0 2.447.07 3.315.073.885.223 1.625.565 2.297a5.834 5.834 0 0 0 2.55 2.549c.635.324 1.331.476 2.154.552l.014.002.361.027 4.113.87a.833.833 0 0 0 1.006-.815V8.38c0-.016 0-.032-.002-.048h.835v9.952a.833.833 0 0 0 .884.832c1.022-.062 1.853-.207 2.598-.586a5.834 5.834 0 0 0 2.549-2.55c.342-.671.492-1.41.565-2.296.07-.868.07-1.946.07-3.315v-.74c0-1.37 0-2.447-.07-3.315-.073-.885-.223-1.625-.565-2.297a5.834 5.834 0 0 0-2.55-2.549c-.671-.342-1.41-.492-2.296-.565-.868-.07-1.946-.07-3.315-.07h-.74ZM10 9.057v9.082l-2.5-.53V8.529l2.5.529ZM5.833 7.5v9.863c-.446-.07-.777-.174-1.058-.317a4.167 4.167 0 0 1-1.82-1.821c-.204-.398-.326-.893-.39-1.675-.064-.793-.065-1.803-.065-3.217v-.666c0-1.414 0-2.425.065-3.216.064-.783.187-1.278.39-1.676a4.167 4.167 0 0 1 1.82-1.82c.398-.204.893-.326 1.676-.39.792-.064 1.802-.065 3.216-.065h.666c1.414 0 2.425 0 3.216.065.783.064 1.278.186 1.676.39a4.167 4.167 0 0 1 1.82 1.82c.203.398.326.893.39 1.676.064.791.065 1.802.065 3.216v.666c0 1.414 0 2.425-.065 3.216-.064.783-.186 1.278-.39 1.676a4.167 4.167 0 0 1-1.82 1.82c-.282.145-.613.247-1.058.318V7.5a.833.833 0 0 0-.834-.833H6.667a.833.833 0 0 0-.834.833Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h20v20H0z" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export default SmartDoorLockOpenDoorWidget;
