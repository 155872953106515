import React from "react";
import WidgetBattery from "../WidgetBattery";

function SmokeSensorBatteryWidget() {
  return (
    <div className="widget">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">화재 감지 센서</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-footer">
        <WidgetBattery battery={100} />
        <span>100%</span>
      </div>
    </div>
  );
}

export default SmokeSensorBatteryWidget;
