import React from "react";
import "../Widget.scss";

function SmartDoorLockTempPasswordWidget() {
  return (
    <div className="widget">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">스마트 도어락</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-footer temp-password">
        <p>임시 비밀번호</p>
        <svg
          width={20}
          height={20}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m8.577 11.423 1.667 1.666 1.179-1.178-1.667-1.667-1.179 1.179Z"
            fill="#797672"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.667 5.417c.48 0 .79 0 1.027.02.226.018.31.05.351.07.157.08.284.208.364.365.021.04.053.125.071.351.017.208.02.473.02.86H6.667a2.5 2.5 0 0 0-2.5 2.5v3.2c0 .438 0 .817.025 1.13.027.329.086.657.247.972a2.5 2.5 0 0 0 1.093 1.092c.315.161.643.22.972.247.312.026.691.026 1.13.026h4.731c.44 0 .819 0 1.13-.026.33-.026.658-.086.973-.247a2.5 2.5 0 0 0 1.093-1.092c.16-.315.22-.643.247-.973.025-.312.025-.69.025-1.13V9.583a2.501 2.501 0 0 0-1.666-2.357v-.008c0-.44 0-.818-.026-1.13-.027-.33-.086-.658-.247-.973a2.5 2.5 0 0 0-1.092-1.093c-.316-.16-.643-.22-.973-.247-.312-.025-.69-.025-1.13-.025H8.333a2.5 2.5 0 0 0-2.5 2.5H7.5c0-.46.373-.833.833-.833h2.334ZM5.833 9.583c0-.46.373-.833.834-.833h6.666c.46 0 .834.373.834.833v3.167c0 .48-.001.79-.02 1.027-.019.226-.05.31-.071.351a.833.833 0 0 1-.364.364c-.041.021-.126.053-.352.071-.236.02-.546.02-1.027.02H7.667c-.48 0-.791 0-1.027-.02-.227-.018-.31-.05-.352-.07a.833.833 0 0 1-.364-.365c-.02-.04-.052-.125-.07-.351a14.17 14.17 0 0 1-.02-1.027V9.583Z"
            fill="#797672"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.63.833c-1.37 0-2.447 0-3.315.071-.885.073-1.625.223-2.297.565A5.833 5.833 0 0 0 1.47 4.02c-.342.671-.492 1.41-.565 2.296-.07.868-.07 1.946-.07 3.315v.74c0 1.37 0 2.447.07 3.315.073.885.223 1.625.565 2.297a5.834 5.834 0 0 0 2.55 2.549c.671.342 1.41.492 2.296.565.868.07 1.946.07 3.315.07h.74c1.37 0 2.447 0 3.315-.07.885-.073 1.625-.223 2.297-.565a5.834 5.834 0 0 0 2.549-2.55c.342-.671.492-1.41.565-2.296.07-.868.07-1.946.07-3.315v-.74c0-1.37 0-2.447-.07-3.315-.073-.885-.223-1.625-.565-2.297a5.834 5.834 0 0 0-2.55-2.549c-.671-.342-1.41-.492-2.296-.565-.868-.07-1.946-.07-3.315-.07h-.74ZM4.775 2.954c.398-.203.893-.325 1.676-.389.791-.064 1.802-.065 3.216-.065h.666c1.414 0 2.425 0 3.216.065.783.064 1.278.186 1.676.39a4.167 4.167 0 0 1 1.82 1.82c.203.398.326.893.39 1.676.064.791.065 1.802.065 3.216v.666c0 1.414 0 2.425-.065 3.216-.064.783-.186 1.278-.39 1.676a4.167 4.167 0 0 1-1.82 1.82c-.398.203-.893.326-1.675.39-.793.064-1.803.065-3.217.065h-.666c-1.414 0-2.425 0-3.216-.065-.783-.064-1.278-.186-1.676-.39a4.167 4.167 0 0 1-1.82-1.82c-.204-.398-.326-.893-.39-1.675-.064-.793-.065-1.803-.065-3.217v-.666c0-1.414 0-2.425.065-3.216.064-.783.186-1.278.39-1.676a4.167 4.167 0 0 1 1.82-1.82Z"
            fill="#797672"
          />
        </svg>
      </div>
    </div>
  );
}

export default SmartDoorLockTempPasswordWidget;
