import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Descriptions } from 'antd';
import { useParams } from 'react-router-dom';
function PidDetail() {
  const { id, pid } = useParams();
  const [data, setData] = useState<{
    id: number;
    product_name: string;
    sync_platform_id: string;
    pid: string;
  }>({ id: 0, product_name: '', sync_platform_id: '', pid: '' });
  useEffect(() => {
    axios.get(`https://develop-goqual-admin-api.goqual.io/v1.0/admin/users/${id}/devices/${pid}`).then(response => {
      const data = response.data;
      setData(data.result);
    });
  }, [id, pid]);
  return (
    <div>
      {data && (
        <Descriptions title={pid} bordered>
          <Descriptions.Item label="ID">{data.id}</Descriptions.Item>
          <Descriptions.Item label="PRODUCTNAME">{data.product_name}</Descriptions.Item>
          <Descriptions.Item label="PID">{data.pid}</Descriptions.Item>
          <Descriptions.Item label="SYNCPLATFORMID">{data.sync_platform_id}</Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
}
export default PidDetail;
