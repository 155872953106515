import React, { useEffect, useState } from 'react';
import { Space, Table } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';

const { Column } = Table;

function UserList() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get('https://develop-goqual-admin-api.goqual.io/v1.0/admin/users').then(response => {
      const data = response.data;
      setData(data.result);
    });
  }, []);
  return (
    <div>
      {data && (
        <Table sticky={true} dataSource={data} rowKey="goqual_user_info_id">
          <Column title="ID" dataIndex="goqual_user_info_id" key="goqual_user_info_id" />
          <Column title="EMAIL" dataIndex="goqual_user_info_email" key="goqual_user_info_email" />
          <Column title="USERNAME" dataIndex="goqual_user_info_user_name" key="goqual_user_info_user_name" />
          <Column title="mobile" dataIndex="goqual_user_info_mobile" key="goqual_user_info_mobile" />
          <Column
            title="DEVICECOUNT"
            dataIndex="userCount"
            key="userCount"
            render={(
              text,
              row: {
                goqual_user_info_id: string;
              }
            ) => (
              <Space size="middle" key={text}>
                <Link to={`/users/${row.goqual_user_info_id}/devices`}>{text}</Link>
              </Space>
            )}
          />
        </Table>
      )}
    </div>
  );
}

export default UserList;
