import React from "react";
import WidgetBattery from "../WidgetBattery";
import "../Widget.scss";


function SmartDoorLockBatteryWidget() {
  const battery = 20;

  return (
    <div className="widget">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">무타공 도어락</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>

      <div className="dashboard-widget-footer">
        <WidgetBattery battery={battery} />
        {`${battery}%`}
      </div>
    </div>
  );
}

export default SmartDoorLockBatteryWidget;
