import './App.css';
import { PageLayout } from './common/PageLayout';
import { SWRConfig } from 'swr';
import axios from 'axios';

const fetcher = async (url: string) => {
  const {
    data: { result }
  }: {
    data: {
      result: {
        pidCountList: any[];
        pidPname: {};
      };
    };
  } = await axios.get(url);
  return result;
};

function App() {
  return (
    <SWRConfig
      value={{
        refreshInterval: 5000,
        fetcher,
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          if (error.status === 404) return;
          if (retryCount >= 5) return;
          setTimeout(() => revalidate({ retryCount }), 5000);
        }
      }}
    >
      <PageLayout />
    </SWRConfig>
  );
}

export default App;
