import { Space, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PairingAPMode from './PairingApMode';
import PairingBluetoothMode from './PairingBluetoothMode';
import PairingEZMode from './PairingEZMode';
import PairingZigbeeMode from './PairingZigbeeMode';
const { TabPane } = Tabs;

type Product = {
  id: number;
  isDeleted: number;
  pair_advance: number;
  pair_easy: number;
  pair_ble: number;
  pair_zigbee: number;
};

type PairingData = {
  AP: {
    Multi: [
      { step: string; title: string; content: string; media: [{ mediaSrc: string; mediaType: string; order: number }] }
    ];
    guideLink: string;
  };
  BL: {
    Multi: [
      { step: string; title: string; content: string; media: [{ mediaSrc: string; mediaType: string; order: number }] }
    ];
    guideLink: string;
  };
  EZ: {
    Multi: [
      { step: string; title: string; content: string; media: [{ mediaSrc: string; mediaType: string; order: number }] }
    ];
    guideLink: string;
  };
  ZIG: {
    Multi: [
      { step: string; title: string; content: string; media: [{ mediaSrc: string; mediaType: string; order: number }] }
    ];
    guideLink: string;
  };
};

function PairingManage() {
  const { id, pairingType } = useParams();
  const [product, setProduct] = useState<Product | null>(null);
  const [pairingData, setPairingData] = useState<PairingData | null>(null);

  useEffect(() => {
    async function getDetail() {
      const { data } = await axios.get(`https://develop-goqual-admin-api.goqual.io/v1.0/admin/products/detail/${id}`);
      return data;
    }

    getDetail().then(data => {
      setProduct(data.result);
      setPairingData(JSON.parse(data.result.pairing_data));
    });
  }, [id]);

  const onChangeInput = (e: any) => {
    // console.log(e.target.value);
  };

  return (
    <div className="sku-list">
      <Space>
        <Tabs type="card">
          {pairingType === 'pair_ap' && product?.pair_advance && pairingData && (
            <TabPane tab="AP Mode" key="1">
              <PairingAPMode data={pairingData.AP} onChange={onChangeInput} />
            </TabPane>
          )}
          {pairingType === 'pair_ble' && product?.pair_ble && pairingData && (
            <TabPane tab="Bluetooth Mode" key="2">
              <PairingBluetoothMode data={pairingData.BL} onChange={onChangeInput} />
            </TabPane>
          )}
          {pairingType === 'pair_ez' && product?.pair_easy && pairingData && (
            <TabPane tab="EZ Mode" key="3">
              <PairingEZMode data={pairingData.EZ} onChange={onChangeInput} />
            </TabPane>
          )}
          {pairingType === 'pair_zig' && product?.pair_zigbee && pairingData && (
            <TabPane tab="Zigbee Mode" key="4">
              <PairingZigbeeMode data={pairingData.ZIG} onChange={onChangeInput} />
            </TabPane>
          )}
        </Tabs>
      </Space>
    </div>
  );
}

export default PairingManage;
