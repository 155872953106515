import React from 'react';
import RadarSensorWidget from "./RadarSensorWidget";
import RadarSensorWideWidget from "./RadarSensorWideWidget";

type Props = {
  selectedWidget: string;
}

function RadarSensorContainer({ selectedWidget }: Props) {
  return (
    <>
      {selectedWidget === 'RadarSensorWidget' && <RadarSensorWidget />}
      {selectedWidget === 'RadarSensorWideWidget' && <RadarSensorWideWidget />}
    </>
  );
}

export default RadarSensorContainer;
