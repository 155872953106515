import { Table } from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
const { Column } = Table;

function PidDetailEventList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(`https://develop-goqual-admin-api.goqual.io/v1.0/influx/pid/${id}`).then(response => {
      const data = response.data;
      setData(data.result);
    });
  }, [id]);
  return (
    <>
      {data && (
        <Table sticky={true} dataSource={data}>
          <Column title="TIME" dataIndex="_time" key="_time" />
          <Column title="ProductKey" dataIndex="productKey" key="productKey" />
          <Column title="DeviceId" dataIndex="deviceId" key="deviceId" />
          <Column title="EventTime" dataIndex="t" key="t" />
        </Table>
      )}
    </>
  );
}

export default PidDetailEventList;
