import React from 'react';
import SwitchControlWidget from "./SwitchControlWidget";

type Props = {
  selectedWidget: string;
}

function SwitchWidgetContainer({ selectedWidget }: Props) {



  return (
    <>
      {selectedWidget.includes('1ControlWidget') && <SwitchControlWidget deviceName="스위치 1구" status={{ power1: false }}/>}
      {selectedWidget.includes('_2ControlWidget') && <SwitchControlWidget deviceName="스위치 2구" status={{ power2: true }}/>}
      {selectedWidget.includes('_3ControlWidget') && <SwitchControlWidget deviceName="스위치 3구" status={{ power3: true }}/>}
      {selectedWidget.includes('_4ControlWidget') && <SwitchControlWidget deviceName="스위치 4구" status={{ power4: false }}/>}
      {selectedWidget.includes('_5ControlWidget') && <SwitchControlWidget deviceName="스위치 5구" status={{ power5: true }}/>}
      {selectedWidget.includes('_6ControlWidget') && <SwitchControlWidget deviceName="스위치 5구" status={{ power6: true }}/>}
      {selectedWidget === 'Switch2ControlWidget' && <SwitchControlWidget deviceName="스위치 2구" status={{ power1: false, power2: true }} />}
      {selectedWidget === 'Switch3ControlWidget' && <SwitchControlWidget deviceName="스위치 3구" status={{ power1: false, power2: true, power3: false }} />}
      {selectedWidget === 'Switch4ControlWidget' && <SwitchControlWidget deviceName="스위치 4구" status={{ power1: false, power2: true, power3: false, power4: true }} />}
      {selectedWidget === 'Switch5ControlWidget' && <SwitchControlWidget deviceName="스위치 5구" status={{ power1: false, power2: true, power3: false, power4: true, power5: true }} />}
      {selectedWidget === 'Switch6ControlWidget' && <SwitchControlWidget deviceName="스위치 6구" status={{ power1: false, power2: true, power3: false, power4: true, power5: true, power6: false }} />}
    </>
  );
}

export default SwitchWidgetContainer;
