import React from "react";

function SmartDoorLockOnlineWidget() {
  return (
    <div className="widget">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">스마트 도어락</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className={`dashboard-widget-info`}>온라인</div>
    </div>
  );
}

export default SmartDoorLockOnlineWidget;
