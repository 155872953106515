import { Layout, Menu } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Sider } = Layout;

export const NavigationBar = () => {
  return (
    <Sider>
      <div className="logo" />
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item key={'1'} icon={<StarOutlined />}>
          <Link to="/">home</Link>
        </Menu.Item>
        <Menu.Item key={'2'}>
          <Link to="/users">사용자 관리</Link>
        </Menu.Item>
        <Menu.Item key={'3'}>
          <Link to="/pids">상품관리</Link>
        </Menu.Item>
        <Menu.Item key={'4'}>
          <Link to="/product/category">상품카테고리 관리</Link>
        </Menu.Item>
        <Menu.Item key={'5'}>
          <Link to="/widgets">위젯 관리</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
