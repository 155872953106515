import React from "react";
import WidgetBattery from "../WidgetBattery";

function DoorSensorBatteryWidget() {

  return (
    <div className="widget">
      <div className="dashboard-widget-title">
        <h1 className="dashboard-widget-title-name">도어센서</h1>
        <p className="dashboard-widget-title-place">장소</p>
      </div>
      <div className="dashboard-widget-footer">
        <WidgetBattery battery={32} />
        <span>{32}%</span>
      </div>
    </div>
  );
}

export default DoorSensorBatteryWidget;
